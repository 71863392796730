export default {
  methods: {
    title: "Onze betalingsmethoden"
  },
  payment: "Betaling",
  checkout_title: "Overzicht van betaling",
  handle_payment: "Betaling afhandelen",
  start_payment: "Start betaling",
  cancel_payment: "Annuleer betaling",
  payment_status: "Betalingsstatus",
  will_be_redirected: "U wordt doorgestuurd naar een beveiligde betaalpagina.",
  laundry: {
    payment_success: "U kunt nu de geselecteerde machine in gebruik nemen.",
    payment_issues: "Er lijken problemen te zijn met uw betaling.",
    payment_issues_contact: "Als je betaald hebt en dit bericht krijgt, neem dan gerust contact op met de conciërge"
  },
  reservation: {
    payment_success: "De betaling voor uw reservatie is geslaagd!",
    payment_issues: "Er lijkt zich een probleem te hebben voorgedaan tijdens de betaling.",
    payment_issues_contact: "Indien de betaling volgens u is doorgegaan en u deze melding krijgt kan u contact opnemen met ons sales team."
  },
  back_to_home: "Terug naar de startpagina",
  confirm_and_pay: "Bevestig en betaal"
}
