export type ImageData = {
  id: number;
  path: string;
  alt_tag: string;
  created_at: string;
  updated_at: string;
};

export default class Image {
  public id: number;
  public path: string;
  public altTag: string;
  public createdAt: string;
  public updatedAt: string;

  constructor(data: ImageData) {
    this.id = data.id as number;
    this.path = data.path as string;
    this.altTag = data.alt_tag as string;
    this.createdAt = data.created_at as string;
    this.updatedAt = data.updated_at as string;
  }

  toJson(): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (this.id !== null) json.id = this.id;
    if (this.path !== null) json.path = this.path;
    if (this.altTag !== null) json.alt_tag = this.altTag;
    if (this.createdAt !== null) json.created_at = this.createdAt;
    if (this.updatedAt !== null) json.updated_at = this.updatedAt;

    return json;
  }

}
