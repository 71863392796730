export default {
  unit_picker: {
    select_unit: "Sélectionnez votre chambre",
    multiple_units_found: "Plusieurs contrats ont été trouvés pour cette réservation. Sélectionnez votre chambre pour continuer.",
    dialog: {
      confirm_unit: "Confirmez votre chambre",
      staying_in_unit: "Vous séjournez dans la chambre",
      logged_in_unit: "Vous serez connecté pour cette chambre.",
      cancel: "Annuler",
      confirm: "Confirmer"
    }
  }
}
