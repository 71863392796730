import ReceptionCheckInOutData, {
  IReceptionCheckInOutData
} from "@/types/clients/esquire/data/general/reception-check-in-out-data";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";

dayjs.extend(weekday);
export interface IReceptionTime {
  checkin: IReceptionCheckInOutData;
  checkout: IReceptionCheckInOutData;
}

export default class ReceptionTime {
  public checkin: IReceptionCheckInOutData
  public checkout: IReceptionCheckInOutData

  constructor(data: IReceptionTime) {
    this.checkin = new ReceptionCheckInOutData(data.checkin);
    this.checkout = new ReceptionCheckInOutData(data.checkout);
  }

  public receptionCheckInFrom(dateString = ""): string {
    const date = dateString ? dayjs(dateString) : dayjs();
    const day = date.format("ddd").toLowerCase();

    switch (day) {
      case "mon":
        return this.checkin.mon.from;
      case "tue":
        return this.checkin.tue.from;
      case "wed":
        return this.checkin.wed.from;
      case "thu":
        return this.checkin.thu.from;
      case "fri":
        return this.checkin.fri.from;
      case "sat":
        return this.checkin.sat.from;
      case "sun":
        return this.checkin.sun.from;
      default:
        return "";
    }
  }

  receptionCheckInUntil(dateString = ""):string {
    const date = dateString ? dayjs(dateString) : dayjs();
    const day = date.format("ddd").toLowerCase();


    switch (day) {
      case "mon" :
        return this.checkin.mon.until;
      case "tue" :
        return this.checkin.tue.until;
      case "wed" :
        return this.checkin.wed.until;
      case "thu" :
        return this.checkin.thu.until;
      case "fri" :
        return this.checkin.fri.until;
      case "sat" :
        return this.checkin.sat.until;
      case "sun" :
        return this.checkin.sun.until;
    }

    return "";
  }
}
