import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

export default createVuetify({
  components,
  directives,
  theme: {
    themes: {
      light: {
        colors: {
          primary: "#b41502",
          secondary: "#211f1f",
          tertiary: "#989898"
        }
      }
    }
  }
});
