export default {
  home: {
    laundry: "Laundry",
    description: "Select your device, pay, and start using it instantly!",
    scan_QR: "Scan QR code"
  },
  washing_machine: "Washing machine",
  tumble_dryer: "Tumble dryer",
  dialog: {
    attention: " Attention ",
    text_1: "Would you like to pay ",
    text_2: " to use our ",
    text_3: " for 90 minutes?",
    text_4: "After payment the machine will start running immediately.",
    text_5: "We advice to first insert your laundry in the machine.",
    text_6: "You will get 90 minutes of power.",
    text_programs: "You will get 90 minutes of power, this is sufficient for the following programs:",
    programs:{
      washer_1: "Xpress supershort (14 min)",
      washer_2: "Daily express (28 min)",
      washer_3: "Dark on 40°C at 800 rpm (90 min)",
      washer_4: "Synthethic on 40°C at 800 rpm (90 min)"
    }
  },
  dialog_ba: {
    attention: " Attention ",
    text_1: "Would you like to pay ",
    text_2: " to use our ",
    text_3: " for 120 minutes?",
    text_4: "After payment the machine will start running immediately.",
    text_5: "We advice to first insert your laundry in the machine.",
    text_6: "You will get 120 minutes of power.",
    text_programs: "You will get 120 minutes of power, this is sufficient for the following programs:",
    programs:{
      washer_1: "Xpress supershort (14 min)",
      washer_2: "Daily express (28 min)",
      washer_3: "Dark on 40°C at 800 rpm (90 min)",
      washer_4: "Synthethic on 40°C at 800 rpm (90 min)"
    }
  },
  available_in: "Available in",
  no_laundry_devices_found: "No laundry devices could be found for this building"
};
