export default {
  contract_id: "ID du contrat",
  checkin: {
    fill_in: "Entrez les détails du voyageur",
    private: "Privé",
    business: "Entreprise",
    company_name: "Nom de l'entreprise",
    gender: "Sexe",
    first_name: "Prénom",
    last_name: "Nom de famille",
    street: "Rue",
    zip: "Code postal",
    phone_number: "Numéro de téléphone",
    email: "E-mail",
    continue: "Continuer"
  },
  options: {
    options: "Options",
    select_additional: "Sélectionner des options supplémentaires"
  },
  overview: {
    overview: "Aperçu",
    name: "Nom",
    address: "Adresse",
    phone: "Téléphone",
    email: "E-mail",
    invoice_lines: "Lignes de facture",
    removed_options: "Options supprimées",
    added_options: "Options nouvellement ajoutées",
    continue_pay_later: "Continuez et payez plus tard",
    contract: "Contrat",
    guest_info: "Informations sur l'invité",
    total_pay_today: "Total à payer aujourd'hui : ",
    confirm_and_pay: "Confirmer et payer maintenant",
    terms_and_conditions: "Conditions générales",
    accept_terms_and_conditions: "J'accepte les conditions générales"
  },
  passport: {
    passport_scanner: "Scanner de passeport",
    legal_1: "En raison d'exigences légales, nous devons scanner votre passeport pour vérification. Veuillez télécharger une image claire du côté contenant la zone lisible par machine (MRZ).",
    legal_2: "Il se compose de deux ou trois lignes de chiffres, de lettres et de symboles. Ces informations sont cruciales pour l'authentification de votre passeport.",
    mrz_look_like: "A quoi ressemble une MRZ ?",
    mrz_example: "Exemple MRZ",
    close: "Fermer",
    upload_passport: "Télécharger le passeport",
    warning_overwrite: "Attention : le téléchargement d'un nouveau passeport écrasera votre passeport actuel.",
    rejected: "Votre passeport a été rejeté.",
    reason: "Raison: ",
    overwrite_passport: "Écraser le passeport",
    process_passport: "Traitement du passeport",
    passport_data: "Données du passeport",
    gender: "Genre",
    nationality : "Nationalité",
    first_name: "Prénom",
    last_name: "Nom de famille",
    is_data_correct: "Les données du passeport sont-elles correctes ?",
    yes: "oui",
    no: "non",
    password_approved: "Passeport approuvé",
    awaiting_approval: "En attente de contrôle manuel du passeport par l'équipe commerciale",
    failed_to_process: "Échec du traitement du passeport. Veuillez vous assurer que le passeport est clairement visible.",
    passport_confirmation: "Confirmation du passeport",
    picture_correct: "L'image est correcte",
    correct_picture_wrong_data: "L'image que vous avez téléchargée est correcte mais les données sont mal lues : ",
    sales_team_manual_lookup: "notre équipe commerciale inspectera manuellement cette image et remplira les données correctes.",
    upload_new_picture: "Télécharger une nouvelle image",
    remove_picture_upload_new: "La photo actuelle sera supprimée et vous pourrez télécharger une nouvelle photo de passeport."
  },
  key_pickup: {
    key_pickup_instructions: "Instructions pour la récupération des clés",
    gate_code: "Code de la porte : ",
    vault_code: "Code du coffre : ",
    check_in: "Vous pouvez vous enregistrer à notre réception ouverte le [arrival_date] entre [checkin_time] et [checkout_time]",
    late_arrival_notice: "Attention : Arrivée après [checkin_day] ou avez-vous réservé un enregistrement anticipé ?",
    follow_instructions: "Ensuite, suivez les instructions suivantes : ",
    access_entrance: "Pour accéder au hall d'entrée, entrez le code ",
    entrance_description: "Dans le hall d'entrée, vous verrez le coffre où vous trouverez la clé de votre chambre et quelques informations.",
    code_safe: "Le code de ce coffre est ",
    safe_instructions: "Tournez la serrure du coffre vers la droite pour ouvrir la porte/à gauche pour la refermer. ",
    inside_envelope_letter: "À l'intérieur, vous trouverez une enveloppe avec la lettre ",
    personal_envelope: ", ceci est votre enveloppe personnelle. ",
    inside_envelope: "À l'intérieur, vous trouverez une enveloppe avec ",
    your_name: "VOTRE NOM ",
    key_document_inside: "Votre clé avec le numéro de chambre et le document sont à l'intérieur de cette enveloppe, ainsi que les coordonnées. ",
    contact_residence: "En cas de difficulté, vous pouvez contacter le gérant de la résidence au numéro d'urgence suivant : ",
    complete_checkin_for_instructions: "Complétez votre enregistrement pour accéder à vos instructions de récupération de clé.",
    instructions_on_arrival_day: "Le jour de votre arrivée, vous trouverez ici vos instructions d'enregistrement."
  }


}
