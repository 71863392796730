import { App } from "vue";
import Translator from "@/types/services/Translator";

const translatorPlugin = {
  install(app: App) {
    app.config.globalProperties.$translatorUtils = Translator;
  }
};

export default translatorPlugin;
