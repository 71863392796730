import ReceptionTime, {IReceptionTime} from "@/types/clients/esquire/data/general/reception-time";
import BuildingImage, {BuildingImageData} from "@/types/clients/esquire/data/general/building-image";

export interface IBuilding {
  id: string;
  name: string;
  address: string;
  zip: string;
  city: string;
  emergency_phone_number: string;
  reception_times: IReceptionTime;
  from: string;
  until: string;
  building_image: BuildingImageData;
}

export default class Building {
  public id: string;
  public name: string;
  public address: string;
  public zip: string;
  public city: string;
  public emergencyPhoneNumber: string;
  public receptionTimes?: ReceptionTime;
  public from: string;
  public until: string;
  public buildingImage: BuildingImage|null;

  constructor(data: IBuilding) {
    this.id = data.id;
    this.name = data.name;
    this.address = data.address;
    this.zip = data.zip;
    this.city = data.city;
    this.emergencyPhoneNumber = data.emergency_phone_number;
    if (data.reception_times) {
      this.receptionTimes = new ReceptionTime(data.reception_times as IReceptionTime);
      this.from = this.receptionTimes.receptionCheckInFrom();
      this.until = this.receptionTimes.receptionCheckInUntil();
    } else {
      this.receptionTimes = data.reception_times;
      this.from = "";
      this.until = "";
    }
    this.buildingImage = data.building_image ? new BuildingImage(data.building_image) : null;

  }

  toJson(): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (this.id !== null) json.id = this.id;
    if (this.name !== null) json.name = this.name;
    if (this.address !== null) json.address = this.address;
    if (this.zip !== null) json.zip = this.zip;
    if (this.city !== null) json.city = this.city;
    if (this.emergencyPhoneNumber !== null) json.emergency_phone_number = this.emergencyPhoneNumber;
    if (this.receptionTimes !== null) json.reception_times = this.receptionTimes;
    if (this.from !== null) json.from = this.from;
    if (this.until !== null) json.until = this.until;

    return json;
  }

}

