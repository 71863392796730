export default {
  home: {
    domotics: "Domotics",
    thermostat: "Thermostat",
    statistics: "Statistics",
    devices: "Devices",
    expenses: "Expenses",
    energy_today: "Electricity consumed today",
    energy_total: "Total electricity consumed",
    advance_payment: "Advance payment (incl. water)",
    target_per_day: "Projected consumption per day",
    dialog_info: "The color of the cost bar is an indication of consumption versus target consumption.",
    dialog_green: "Consumption is below projected consumption",
    dialog_orange: "Consumption is above the projected consumption",
    dialog_red: "Consumption is far above the projected consumption",
    dialog_questions: "More questions?",
    dialog_contact: "Feel free to contact",
    dialog_sales_team: "our sales team"
  },
  thermostat: {
    thermostat: "Thermostat",
    temperature: "Temperature",
    off_info: "Thermostat is off. No temperature can be set.",
    target_temperature: "Target temperature",
    current_temperature: "Current temperature",
    triggers: "Triggers",
    triggers_schedule: "Triggers schedule",
    dialog_info: "This page exists out of 4 sections.",
    dialog_current: "Here you can find the most recent temperature measurement and when it was taken.",
    dialog_target: "Here you can find and manage the target temperature for your room.",
    dialog_triggers: "Here you can find and manage the triggers for your room. With these, you can set your temperature for different times during the day.",
    dialog_schedule: "And finally, in the table below you can find the weekly schedule for your triggers.",
    last_measurement: "Last measurement",
    confirm_trigger_changes: "Confirm changes",
    unavailable: "No thermostat available",
    thermostat_settings: "Thermostat settings"
  },
  thermostat_settings: {
    triggers: "Triggers"
  },
  statistics: {
    statistics: "Statistics",
    temperature_24_hours: "Temperature past 24 hours",
    min_temperature: "Minimum temperature",
    max_temperature: "Maximum temperature",
    avg_temperature: "Average temperature",
    consumption_24_hours: "Consumption past 24 hours",
    min_consumption: "Minimum consumption",
    max_consumption: "Maximum consumption",
    avg_consumption: "Average consumption",
    total_consumption: "Total consumption",
    power_unavailable: "No power device available"
  },
  devices: {
    devices: "Devices",
    manage: "Manage devices",
    dialog_info: "Here the state of your devices can be managed. A device has 3 possible states:",
    power: "Power",
    on: "on",
    off: "off",
    unavailable: "unavailable",
    thermostat: "Thermostat"
  }
};
