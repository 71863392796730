export default {
  methods: {
    title: "Nos modes de paiement"
  },
  payment: "Paiement",
  checkout_title: "Récapitulatif du paiement",
  handle_payment: "Gérer le paiement",
  start_payment: "Démarrer paiement",
  cancel_payment: "Annuler le paiement",
  payment_status: "Statut de paiement",
  will_be_redirected: "Vous serez redirigé vers une page de paiement sécurisée.",
  laundry: {
    payment_success: "Vous pouvez maintenant commencer à utiliser la machine sélectionnée.",
    payment_issues: "Il semble y avoir des problèmes avec votre paiement.",
    payment_issues_contact: "Si vous avez payé et recevez ce message, n'hésitez pas à contacter le concierge"
  },
  reservation: {
    payment_success: "Le paiement de votre réservation a réussi!",
    payment_issues: "Un problème semble être survenu lors du paiement.",
    payment_issues_contact: "Si vous pensez que le paiement a été effectué et que vous recevez ce message, vous pouvez contacter notre équipe commerciale."
  },
  back_to_home: "Retour à la page d'accueil",
  confirm_and_pay: "Confirmer et payer"
}
