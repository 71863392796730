export default {
  home: {
    laundry: "Blanchisserie",
    description: "Sélectionnez votre appareil, payez et commencez à l'utiliser instantanément!",
    scan_QR: "Scannez le code QR"
  },
  washing_machine: "Lave-linge",
  tumble_dryer: "Sèche-linge",
  dialog: {
    attention: " Attention ",
    text_1: "Souhaitez-vous payer ",
    text_2: " pour utiliser notre ",
    text_3: " pendant 90 minutes ?",
    text_4: "Après le paiement, la machine démarrera immédiatement.",
    text_5: "Nous vous conseillons de d'abord insérer votre linge dans la machine.",
    text_6: "Vous disposerez de 90 minutes de courant.",
    text_programs: "Vous disposerez de 90 minutes de puissance, ce qui est suffisant pour les programmes suivants:",
    programs: {
      washer_1: "Xpress supershort (14 min)",
      washer_2: "Daily Express (28 min)",
      washer_3: "Foncé à 40°C à 800 tr/min (90 min)",
      washer_4: "Synthétique à 40°C à 800 tr/min (90 min)"
    }
  },
  dialog_ba: {
    attention: " Attention ",
    text_1: "Souhaitez-vous payer ",
    text_2: " pour utiliser notre ",
    text_3: " pendant 120 minutes ?",
    text_4: "Après le paiement, la machine démarrera immédiatement.",
    text_5: "Nous vous conseillons de d'abord insérer votre linge dans la machine.",
    text_6: "Vous disposerez de 120 minutes de courant.",
    text_programs: "Vous disposerez de 120 minutes de puissance, ce qui est suffisant pour les programmes suivants:",
    programs: {
      washer_1: "Xpress supershort (14 min)",
      washer_2: "Daily Express (28 min)",
      washer_3: "Foncé à 40°C à 800 tr/min (90 min)",
      washer_4: "Synthétique à 40°C à 800 tr/min (90 min)"
    }
  },
  available_in: "Disponible dans",
  no_laundry_devices_found: "Aucun appareil de lavage n'a pu être trouvé pour cet immeuble"


};
