import Language from "@/types/enums/language";

export interface IContact {
  first_name: string;
  last_name: string;
  gender: string;
  type: string;
  company_name?: string;
  company_type?: string;
  company_vat_code?: string;
  street: string;
  zipcode: string;
  city: string;
  country: string;
  country_name?: string;
  email?: string;
  phone?: string;
  language?: string;
  is_vip?: boolean;
  is_allowed_without_payment?: boolean;
}

export default class Contact {
  public firstName: string;
  public lastName: string;
  public gender: string;
  public type: string;
  public companyName: null | string;
  public companyType: null | string;
  public companyVatCode: null | string;
  public street: string;
  public zipcode: string;
  public city: string;
  public country: string;
  public countryName: null | string;
  public email: string;
  public phone: string;
  public language: string;
  public isVip: boolean;
  public isAllowedWithoutPayment: boolean;

  constructor(data: IContact) {
    this.firstName = data.first_name;
    this.lastName = data.last_name;
    this.gender = data.gender;
    this.type = data.type;
    this.companyName = data.company_name ?? null;
    this.companyType = data.company_type ?? null;
    this.companyVatCode = data.company_vat_code ?? null;
    this.street = data.street;
    this.zipcode = data.zipcode;
    this.city = data.city;
    this.country = data.country;
    this.countryName = data.country_name ?? null;
    this.email = data.email ?? "";
    this.phone = data.phone ?? "";
    this.language = data.language ?? Language.EN;
    this.isVip = data.is_vip ?? false;
    this.isAllowedWithoutPayment = data.is_allowed_without_payment ?? false;
  }

  toJson(): Record<string, unknown>{
    const json: Record<string, unknown> = {};
    if (this.firstName !== null) json.first_name = this.firstName;
    if (this.lastName !== null) json.last_name = this.lastName;
    if (this.gender !== null) json.gender = this.gender;
    if (this.type !== null) json.type = this.type;
    if (this.companyName !== null) json.company_name = this.companyName;
    if (this.companyType !== null) json.company_type = this.companyType;
    if (this.companyVatCode !== null) json.company_vat_code = this.companyVatCode;
    if (this.street !== null) json.street = this.street;
    if (this.zipcode !== null) json.zipcode = this.zipcode;
    if (this.city !== null) json.city = this.city;
    if (this.country !== null) json.country = this.country;
    if (this.countryName !== null) json.country_name = this.countryName;
    if (this.email !== null) json.email = this.email;
    if (this.phone !== null) json.phone = this.phone;
    if (this.language !== null) json.language = this.language;
    if (this.isVip !== null) json.is_vip = this.isVip;
    if (this.isAllowedWithoutPayment !== null) json.is_allowed_without_payment = this.isAllowedWithoutPayment;

    return json;
  }

  public name(): string {
    return this.isBusiness()
      ? `${this.companyName ?? ""} ${this.companyType ?? ""}`
      : `${this.firstName ?? ""} ${this.lastName ?? ""}`;
  }

  public isBusiness(): boolean {
    return this.type === "B";
  }

  public isPrivate(): boolean {
    return this.type === "P";
  }
}
