export default {
  home: {
    domotics: "Domotica",
    thermostat: "Thermostaat",
    statistics: "Statisteken",
    devices: "Apparaten",
    expenses: "Kosten",
    energy_today: "Elektriciteitsverbruik vandaag",
    energy_total: "Totaal elektriciteitsverbruik",
    advance_payment: "Betaald voorschot (incl. water)",
    target_per_day: "Voorzien verbruik per dag",
    dialog_info: "De kleur van de kostenbalk is een indicatie van het verbruik tegenover doelverbruik.",
    dialog_green: "Verbruik zit onder het voorziene verbruik",
    dialog_orange: "Verbruik zit boven het voorziene verbruik",
    dialog_red: "Verbruik zit ver boven het voorziene verbruik",
    dialog_questions: "Meer vragen?",
    dialog_contact: "Neem gerust contact op",
    dialog_sales_team: "ons sales team"
  },
  thermostat: {
    thermostat: "Thermostaat",
    temperature: "Temperatuur",
    off_info: "Thermostaat is uitgeschakeld. Er kan geen doeltemperatuur worden ingesteld.",
    target_temperature: "Doeltemperature",
    current_temperature: "Huidige temperatuur",
    triggers: "Triggers",
    triggers_schedule: "Triggers schema",
    dialog_info: "Deze pagina bestaat uit 4 secties.",
    dialog_current: "Hier kunt u de meest recente temperatuurmeting en het tijdstip waarop deze is genomen vinden.",
    dialog_target: "Hier kunt u de doeltemperatuur voor uw kamer vinden en beheren.",
    dialog_triggers: "Hier kunt u de triggers voor uw kamer vinden en beheren. Hiermee stel je je temperatuur in voor verschillende momenten van de dag.",
    dialog_schedule: "En ten slotte, in de onderstaande tabel vindt u het wekelijkse schema voor uw triggers.",
    last_measurement: "Laatste meting",
    confirm_trigger_changes: "Wijzigingen bevestigen",
    unavailable: "Geen thermostaat beschikbaar",
    thermostat_settings: "Thermostaatinstellingen"
  },
  thermostat_settings: {
    triggers: "Triggers"
  },
  statistics: {
    statistics: "Statistieken",
    temperature_24_hours: "Temperatuur afgelopen 24 uur",
    min_temperature: "Minimumtemperatuur",
    max_temperature: "Maximumtemperatuur",
    avg_temperature: "Gemiddelde temperatuur",
    consumption_24_hours: "Verbruik afgelopen 24 uur",
    min_consumption: "Minimumverbruik",
    max_consumption: "Maximumverbruik",
    avg_consumption: "Gemiddeld verbruik",
    total_consumption: "Totaalverbruik",
    power_unavailable: "Geen stroomapparaat beschikbaar"
  },
  devices: {
    devices: "Apparaten",
    manage: "Apparaten beheren",
    dialog_info: "Hier kan de status van uw apparaten worden beheerd. Een apparaat heeft 3 mogelijke statussen:",
    power: "Stroom",
    on: "aan",
    off: "uit",
    unavailable: "niet beschikbaar",
    thermostat: "Thermostaat"
  }
};
