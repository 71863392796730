import Building, {IBuilding} from "@/types/clients/esquire/data/general/building";
import Contact, {IContact} from "@/types/clients/esquire/data/general/contact";
import Contract, {IContractData} from "@/types/clients/esquire/data/general/contract";
import _ from "lodash";
import dayjs from "dayjs";
import PendingPayment, {IPendingPayment} from "@/types/clients/esquire/data/general/pending-payment";

export interface IReservation {
  id: string;
  channel_id: string;
  checkin_performed_at: null|string;
  building: IBuilding;
  contact: IContact;
  contracts: IContractData[];
  pending_payment: null|IPendingPayment;
}

export default class Reservation {
  public id: string;
  public channelId: string;
  public checkinPerformedAt: null|string;
  public building: Building;
  public contact: Contact;
  public contracts: Contract[];
  public pendingPayment: null|PendingPayment;

  private readonly _minArrival: string;
  private readonly _maxDeparture: string;

  private readonly _nights: number;

  private readonly _accruedDebt: number;
  private readonly _accruedDebtToday: number;
  private readonly _paid: number;

  constructor(data: IReservation) {
    this.id = data.id;
    this.channelId = data.channel_id;
    this.checkinPerformedAt = data.checkin_performed_at;
    this.building = new Building(data.building as IBuilding);
    this.contact = new Contact(data.contact as IContact);
    this.contracts = data.contracts.map(data => new Contract(data));

    this.pendingPayment = data.pending_payment ? new PendingPayment(data.pending_payment as IPendingPayment) : null;

    this._maxDeparture = this.getMaxDeparture();
    this._minArrival = this.getMinArrival();

    this._nights = this.getNights();

    this._accruedDebt = this.calculateAccruedDebt();
    this._accruedDebtToday = this.calculateAccruedDebtToday();
    this._paid = this.calculatePaid();
  }

  public getPrimaryContract(): Contract | null {
    const contractId = localStorage.getItem("contract-id");
    if (!contractId) return null;

    const primaryContract = this.contracts.find(c => c.id === contractId);
    return primaryContract || null;
  }


  get accruedDebt(): number {
    return this._accruedDebt;
  }

  get hasCancelledContract(): boolean {
    return this.contracts.some(contract => contract.cancelledAt !== null);
  }

  get accruedDebtToday(): number {
    return this._accruedDebtToday;
  }

  get maxDeparture(): string {
    return this._maxDeparture;
  }

  get minArrival(): string {
    return this._minArrival;
  }


  get formattedMaxDeparture(): string {
    return dayjs(this._maxDeparture).format("DD/MM/YYYY");
  }

  get formattedMinArrival(): string {
    return dayjs(this._minArrival).format("DD/MM/YYYY");
  }

  get nights(): number {
    return this._nights;
  }

  get paid(): number {
    return this._paid;
  }

  public calculateAccruedDebt(): number {
    return _.sumBy(this.contracts, (c: Contract) => c.accruedDebt);
  }

  public calculateAccruedDebtToday(): number {
    return _.sumBy(this.contracts, (c: Contract) => c.accruedDebtToday);
  }

  public calculatePaid(): number {
    return _.sumBy(this.contracts, (c: Contract) => c.paid);
  }

  public calculateToPay(): number {
    return _.sumBy(this.contracts, (c: Contract) => c.calculateToPay());
  }

  public calculateExtraOptionsByNights(extraNights: number): number {
    return _.sumBy(this.contracts, (c: Contract) => c.getOptionPricePerAmountOfNights(extraNights));
  }

  public calculateToPayToday(): number {
    return _.sumBy(this.contracts, (c: Contract) => c.calculateToPayToday());
  }

  public getOptionPriceTotal(): number {
    return _.sumBy(this.contracts, (c: Contract) => c.getOptionPriceTotal());
  }

  private getMinArrival(): string {
    return _.chain(this.contracts)
      .map((contract: Contract) => contract.startDate)
      .min()
      .value();
  }

  private getMaxDeparture(): string {
    return _.chain(this.contracts)
      .map((contract: Contract) => contract.endDate)
      .max()
      .value();
  }

  private getNights(): number {
    return dayjs(this.maxDeparture).diff(this.minArrival, "days");
  }

  toJson(): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (this.id !== null) json.id = this.id;
    if (this.channelId !== null) json.channel_id = this.channelId;
    if (this.checkinPerformedAt !== null) json.checkin_performed_at = this.checkinPerformedAt;
    if (this.building !== null) json.building = this.building.toJson();
    if (this.contact !== null) json.contact = this.contact.toJson();
    if (this.contracts !== null) json.contracts = this.contracts.map((c: Contract) => c.toJson());
    if (this.pendingPayment !== null) json.pending_payment = this.pendingPayment.toJson();

    return json;
  }

}
