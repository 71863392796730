import Option, {OptionData} from "@/types/clients/esquire/data/general/option";

export interface IPendingPayment {
    guid: string;
    option: OptionData;
}

export default class PendingPayment {
    public guid: string;
    public option: null|Option;

    constructor(data: IPendingPayment) {
        this.guid = data.guid as string;
        this.option = data.option ? new Option(data.option as OptionData) : null;
    }

    toJson(): Record<string, unknown> {
        const json: Record<string, unknown> = {};
        if (this.guid !== null) json.guid = this.guid;
        if (this.option !== null) json.option = this.option.toJson();

        return json;
    }
}
