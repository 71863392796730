<template>
  <v-app-bar color="primary">
    <v-app-bar-title>Be-Housing Guest App</v-app-bar-title>
    <template v-slot:append>
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn icon="mdi-dots-vertical" v-bind="props"></v-btn>
            </template>
            <v-list>
              <v-list-item>
                <v-list-item-title @click="logOut">
                  <v-btn append-icon="mdi-logout" style="width: 100%">
                    {{ translator.trans("general.menu.log_out") }}
                  </v-btn>
                </v-list-item-title>
              </v-list-item>
              <v-list-item :close-on-content-click="false">
                <v-list-item-title>
                  <v-menu location="start">
                    <template v-slot:activator="{ props }">
                      <v-btn v-bind="props" append-icon="mdi-swap-horizontal">
                        {{ translator.trans("general.menu.change_language") }}
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="changeLanguage('en')">
                        <v-list-item-title>English</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="changeLanguage('nl')">
                        <v-list-item-title>Nederlands</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="changeLanguage('fr')">
                        <v-list-item-title>Français</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
    </template>
  </v-app-bar>
</template>

<script lang="ts" setup>
import { getCurrentInstance } from "vue";
import Translator from "@/types/services/Translator";

const instance = getCurrentInstance();
const router = instance?.appContext.config.globalProperties.$routerUtils;
const translator: typeof Translator = instance?.appContext.config.globalProperties.$translatorUtils;

function logOut() {
  const lang = localStorage.getItem("language") ?? "en";
  localStorage.clear();
  translator.setLanguage(lang);
  router.routeTo("auth");
}
function changeLanguage(language: string) {
  translator.setLanguage(language);
  router.refreshCurrentPage();
}

</script>

<style scoped>

</style>
