import router from "@/router";
import {RouteParamsRaw, useRoute} from "vue-router";
import useStore from "@/stores";

class Router {
  async routeTo(name: string, params: RouteParamsRaw = {}) {
    await router.push({ name: name, params: params });
  }

getBreadCrumbs() {
  return router.currentRoute.value.fullPath
    .split("/")
    .map(segment => ({ name: segment.split("?")[0] || "home" }))
    .filter((item, index, array) => {
      const nextItem = array[index + 1];
      const prevItem = array[index - 1];
      return !(item.name === "home" && array.length > 1 && (nextItem?.name === "reservation-home" || prevItem?.name === "home"));
    });
}


  getLoginParameter() {
    return this.getQueryParams().login?.toString() ?? "";
  }
  refreshCurrentPage() {
    router.go(0);
  }

  getQueryParams() {
    const route = useRoute();
    return route.query;
  }

  saveSignedRoutes(contractId: string, routes: Array<string>) {
    const store = useStore();
    store.session.setSession(contractId.toUpperCase());
    const keys = Object.keys(routes);
    const values = Object.values(routes);
    localStorage.setItem("contract", contractId);
    keys.forEach((item, index) => {
      localStorage.setItem(item, values[index]);
    });
  }
}

export default new Router();
