export default {
  unit_picker: {
    select_unit: "Select your Unit",
    multiple_units_found: "Multiple contracts were found for this reservation. Select your unit to proceed.",
    dialog: {
      confirm_unit: "Confirm your unit",
      staying_in_unit: "Are you staying in unit",
      logged_in_unit: "You will be logged in for this unit.",
      cancel: "Cancel",
      confirm: "Confirm"
    }
  }
}
