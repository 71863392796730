export interface IUnitData {
  id: string;
  unit_type_id: string;
  single_bed_count: number;
  double_bed_count: number;
}

export default class Unit {
  public id: string;
  public unitTypeId: string;
  public singleBedCount: number;
  public doubleBedCount: number;

  constructor(data: IUnitData) {
    this.id = data.id;
    this.unitTypeId = data.unit_type_id;
    this.singleBedCount = data.single_bed_count;
    this.doubleBedCount = data.double_bed_count;
  }

  toJson(): Record<string, unknown> {
    return {
      id: this.id,
      unit_type_id: this.unitTypeId,
      single_bed_count: this.singleBedCount,
      double_bed_count: this.doubleBedCount
    };
  }

}
