import { defineStore } from "pinia";
import { Ref, ref } from "vue";
import Reservation, {IReservation} from "@/types/clients/esquire/data/general/reservation";

export default defineStore("sessionStore",
  () => {
    const contractId = ref(null) as Ref<null|string>;
    const reservation = ref<Reservation | null>(getReservation());

    function setSession(value: string): void {
      contractId.value = value;
    }
    function setReservation(reservationData: Reservation): void {
      reservation.value = reservationData;
      localStorage.setItem("reservation-data", JSON.stringify(reservationData.toJson()));
    }
    function getReservation(): Reservation|null {
        const localStorageReservation = localStorage.getItem("reservation-data");
        if (localStorageReservation){
            return new Reservation(JSON.parse(localStorageReservation) as IReservation);
        }

        return null;
    }

    return {
      contractId,
      setSession,
      reservation,
      setReservation
    };
  });
