export default {
  unit_picker: {
    select_unit: "Selecteer uw kamer",
    multiple_units_found: "Er zijn meerdere contracten gevonden voor deze reservering. Selecteer uw kamer om verder te gaan.",
    dialog: {
      confirm_unit: "Bevestig je kamer",
      staying_in_unit: "Verblijft u in kamer",
      logged_in_unit: "U wordt ingelogd voor deze kamer.",
      cancel: "Annuleer",
      confirm: "Bevestig"
    }
  }
}
