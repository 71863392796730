export default {
  home: {
    domotics: "Domotique",
    thermostat: "Thermostat",
    statistics: "Statistiques",
    devices: "Appareils",
    expenses: "Dépenses",
    energy_today: "Consommation d'électricité aujourd'hui",
    energy_total: "Consommation totale d'électricité",
    advance_payment: "Paiement anticipé (incl. eau)",
    target_per_day: "Consommation prévue par jour",
    dialog_info: "La couleur de la barre de coût est une indication de la consommation par rapport à la consommation cible.",
    dialog_green: "La consommation est inférieure à la consommation prévue",
    dialog_orange: "La consommation est supérieure à la consommation prévue",
    dialog_red: "La consommation est bien supérieure à la consommation prévue",
    dialog_questions: "Plus de questions ?",
    dialog_contact: "N'hésitez pas à contacter",
    dialog_sales_team: "notre équipe commerciale"
  },
  thermostat: {
    thermostat: "Thermostat",
    temperature: "Température",
    off_info: "Le thermostat est éteint. Aucune température ne peut être réglée.",
    target_temperature: "Température cible",
    current_temperature: "Température actuelle",
    triggers: "Déclencheurs",
    triggers_schedule: "Programme des déclencheurs",
    dialog_info: "Cette page se compose de 4 sections.",
    dialog_current: "Ici, vous pouvez trouver la mesure de température la plus récente et l'heure à laquelle elle a été prise.",
    dialog_target: "Ici, vous pouvez trouver et gérer la température cible pour votre pièce.",
    dialog_triggers: "Ici, vous pouvez trouver et gérer les déclencheurs pour votre pièce. Avec ceux-ci, vous pouvez régler votre température à différents moments de la journée.",
    dialog_schedule: "Et enfin, dans le tableau ci-dessous, vous pouvez trouver le programme hebdomadaire de vos déclencheurs.",
    last_measurement: "Dernière mesure",
    confirm_trigger_changes: "Confirmer les modifications",
    unavailable: "Aucun thermostat disponible",
    thermostat_settings: "Paramètres du thermostat"
  },
  thermostat_settings: {
    triggers: "Déclencheurs"
  },
  statistics: {
    statistics: "Statistiques",
    temperature_24_hours: "Température des dernières 24 heures",
    min_temperature: "Température minimale",
    max_temperature: "Température maximale",
    avg_temperature: "Température moyenne",
    consumption_24_hours: "Consommation des dernières 24 heures",
    min_consumption: "Consommation minimale",
    max_consumption: "Consommation maximale",
    avg_consumption: "Consommation moyenne",
    total_consumption: "Consommation totale",
    power_unavailable: "Aucun périphérique d'alimentation disponible"
  },
  devices: {
    devices: "Appareils",
    manage: "Gérer les appareils",
    dialog_info: "Ici, l'état de vos appareils peut être géré. Un appareil a 3 états possibles :",
    power: "Courant",
    on: "allumé",
    off: "éteinte",
    unavailable: "indisponible",
    thermostat: "Thermostat"
  }
};
