export interface IReceptionDayData {
    from: string,
    until: string;
}

export default class ReceptionDayData {
    public from: string;
    public until: string;

    constructor(data: IReceptionDayData) {
        this.from = data.from;
        this.until = data.until;
    }
}
