export default {
  home: {
    reservation_filters: "Reservation filters",
    ongoing_reservations: "Ongoing reservations",
    future_reservations: "Future reservations",
    open_balance: "Open balance",
    past_reservations: "Past reservations",
    reference_number: "Reference number",
    check_in: "Check-in",
    check_out: "Check-out",
    nights: "Nights",
    to_pay_today: "To pay today",
    cancelled: "CANCELLED",
    pay_my_reservation: "PAY MY RESERVATION",
    extend_my_reservation: "EXTEND MY RESERVATION",
    rooms: "Rooms",
    contract_id: "Contract ID",
    arrival: "Arrival",
    departure: "Departure",
    guests: "Guests",
    unit_type: "Unit Type",
    main_guest: "Main guest",
    fetching_reservations: "Fetching reservations"
  },
  extend: {
    find_availabilities: "Find availabilities",

    select_contracts_extend: "Select Contracts to Extend",
    select_all: "Select All",
    deselect_all: "Deselect All",

    id: "ID",
    stay: "Stay",
    unit: "Kamer",
    guest: "Guest",

    pick_new_departure: "Pick a new departure date",
    extend_for_price: "Extend your stay at this room for ",
    extend_for: "Extend for ",
    days: "days",

    pick_options: "Pick your options",
    previous_contract: "Previous contract",
    next_contract: "Next contract",

    reservation_overview: "Reservation overview",
    total_balance: "Total balance",
    confirm_extension: "I confirm my extension by clicking proceed to payment",
    proceed_to_payment: "Proceed to payment",
    go_to_overview: "Go to overview",
    previous_balance: "Previous balance",
    balance_after_extension: "Balance after extension"
  },
  error: {
    no_availabilities: "No availabilities found for this date. Please select another date or contact our sales team.",
    extend_success: "Reservation succesfully extended.",
    extend_failed: "Failed to extend reservation. Please contact our sales team."
  }
}
