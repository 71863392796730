import Translator from "@/types/services/Translator";

class Validator {
    isValidText(text: string): boolean {
        return /^[a-zA-Z\u00C0-\u024F\s]*$/.test(text);
    }

    isValidTextNumber(text: string): boolean {
        return /^[a-zA-Z0-9\u00C0-\u024F\s]*$/.test(text);
    }

    isValidPhoneNumber(phoneNumber: string): boolean {
        return /^\+?\d[\d\s-]{4,14}\d$/.test(phoneNumber);
    }

    isValidEmail(email: string): boolean {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    }

    isNonEmpty(value: string) {
        return !!value.trim();
    }

    textRule(value: string) {
        if (!this.isNonEmpty(value)) return Translator.trans("validator.required");
        if (!this.isValidText(value)) return Translator.trans("validator.alphabetic");
        return true;
    }

    textNumberRule(value: string) {
        if (!this.isNonEmpty(value)) return Translator.trans("validator.required");
        if (!this.isValidTextNumber(value)) return Translator.trans("validator.alphanumeric");
        return true;
    }

    phoneNumberRule(value: string) {
        if (!this.isNonEmpty(value)) return Translator.trans("validator.required");
        if (!this.isValidPhoneNumber(value)) return Translator.trans("validator.phone");
        return true;
    }

    emailRule(value: string) {
        if (!this.isNonEmpty(value)) return Translator.trans("validator.required");
        if (!this.isValidEmail(value)) return Translator.trans("validator.email");
        return true;
    }
}

export default new Validator();
