export default {
  home: {
    reservation_filters: "Reserveringsfilters",
    ongoing_reservations: "Lopende reserveringen",
    future_reservations: "Toekomstige reserveringen",
    open_balance: "Open saldo",
    past_reservations: "Afgelopen reserveringen",
    reference_number: "Referentienummer",
    check_in: "Check-in",
    check_out: "Check-out",
    nights: "Nachten",
    to_pay_today: "Vandaag te betalen",
    cancelled: "GEANNULEERD",
    extend_my_reservation: "VERLENG MIJN RESERVERING",
    rooms: "Kamers",
    contract_id: "Contract ID",
    arrival: "Aankomst",
    departure: "Vertrek",
    guests: "Gasten",
    unit_type: "Kamertype",
    main_guest: "Hoofdgast",
    fetching_reservations: "Reserveringen ophalen"
  },
  extend: {
    find_availabilities: "Zoek beschikbaarheden",
    select_contracts_extend: "Selecteer contracten om te verlengen",
    select_all: "Selecteer alles",
    deselect_all: "Deselecteer alles",
    id: "ID",
    stay: "Verblijf",
    unit: "Kamer",
    guest: "Gast",
    pick_new_departure: "Kies een nieuwe vertrekdatum",
    extend_for_price: "Verleng uw verblijf in deze kamer voor ",
    extend_for: "Verleng voor ",
    days: "dagen",
    pick_options: "Kies uw opties",
    previous_contract: "Vorig contract",
    next_contract: "Volgend contract",
    reservation_overview: "Overzicht van de reservering",
    total_balance: "Totaal saldo",
    confirm_extension: "Ik bevestig mijn verlenging door te klikken op doorgaan naar betaling",
    proceed_to_payment: "Doorgaan naar betaling",
    go_to_overview: "Ga naar overzicht",
    previous_balance: "Vorig saldo",
    balance_after_extension: "Saldo na verlenging"
  }
  ,
  error: {
    no_availabilities: "Geen beschikbaarheid gevonden voor deze datum. Selecteer een andere datum of neem contact op met ons sales team.",
    extend_success: "Reservering succesvol verlengd.",
    extend_failed: "Kan de reservering niet verlengen. Gelieve contact op te nemen met ons sales team."
  }
}
