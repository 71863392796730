export interface IUnitTypeData {
  id: string;
  description: string;
  single_bed_count: number;
  double_bed_count: number;
}

export default class UnitType {
  public id: string;
  public description: string;
  public singleBedCount: number;
  public doubleBedCount: number;

  constructor(data: IUnitTypeData) {
    this.id = data.id;
    this.description = data.description;
    this.singleBedCount = data.single_bed_count;
    this.doubleBedCount = data.double_bed_count;
  }

  toJson(): Record<string, unknown> {
    return {
      id: this.id,
      description: this.description,
      single_bed_count: this.singleBedCount,
      double_bed_count: this.doubleBedCount
    };
  }

}
