export default {
  methods: {
    title: "Our payment methods"
  },
  payment: "payment",
  checkout_title: "Checkout Summary",
  handle_payment: "Handle payment",
  start_payment: "Start payment",
  cancel_payment: "Cancel payment",
  payment_status: "Payment status",
  will_be_redirected: "You will be redirected to a secure payment page.",
  laundry: {
    payment_success: "You can now start using the selected machine.",
    payment_issues: "There seem to be some issues with your payment.",
    payment_issues_contact: "If you paid and are getting this message, feel free to contact the janitor"
  },
  reservation: {
    payment_success: "The payment for your reservation was successful!",
    payment_initiated_1: "The payment for your reservation has not yet been completed.",
    payment_initiated_2: "Please click the button below to complete your payment and secure your reservation.",
    payment_issues: "A problem appears to have occurred during payment.",
    payment_issues_contact: "If you believe the payment has gone through and you receive this message, you can contact our sales team."
  },
  back_to_home: "Back to home page",
  confirm_and_pay: "Confirm and pay"
}
