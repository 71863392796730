import {App} from "vue";
import Validator from "@/types/services/Validator";

const validatorPlugin = {
    install(app: App) {
        app.config.globalProperties.$validatorUtils = Validator;
    }
};

export default validatorPlugin;
