import Image, {ImageData} from "@/types/clients/esquire/data/general/image";

export type BuildingImageData = {
  id: number;
  building_code: string;
  image_id: string;
  is_thumbnail: string;
  alt: string;
  order: string;
  image: ImageData;
};

export default class BuildingImage {
  public id: number;
  public buildingCode: string;
  public imageId: string;
  public isThumbnail: string;
  public alt: string;
  public order: string;
  public image: Image|null;

  constructor(data: BuildingImageData) {
    this.id = data.id as number;
    this.buildingCode = data.building_code as string;
    this.imageId = data.image_id as string;
    this.isThumbnail = data.is_thumbnail as string;
    this.alt = data.alt as string;
    this.order = data.order as string;
    this.image = data.image ? new Image(data.image) : null;
  }

  toJson(): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (this.id !== null) json.id = this.id;
    if (this.buildingCode !== null) json.building_code = this.buildingCode;
    if (this.imageId !== null) json.image_id = this.imageId;
    if (this.isThumbnail !== null) json.is_thumbnail = this.isThumbnail;
    if (this.alt !== null) json.alt = this.alt;
    if (this.order !== null) json.order = this.order;
    if (this.image !== null) json.image = this.image;

    return json;
  }

}
