import NestedRecord from "@/types/interfaces/NestedRecord";
import Language from "@/types/enums/language";

import generalEn from "@/lang/translations/en/general";
import generalFr from "@/lang/translations/fr/general";
import generalNl from "@/lang/translations/nl/general";

import adminEn from "@/lang/translations/en/admin";
import adminFr from "@/lang/translations/fr/admin";
import adminNl from "@/lang/translations/nl/admin";

import domoticsEn from "@/lang/translations/en/domotics";
import domoticsFr from "@/lang/translations/fr/domotics";
import domoticsNl from "@/lang/translations/nl/domotics";

import loginEn from "@/lang/translations/en/login";
import loginFr from "@/lang/translations/fr/login";
import loginNl from "@/lang/translations/nl/login";

import laundryEn from "@/lang/translations/en/laundry";
import laundryFr from "@/lang/translations/fr/laundry";
import laundryNl from "@/lang/translations/nl/laundry";

import paymentEn from "@/lang/translations/en/payment";
import paymentFr from "@/lang/translations/fr/payment";
import paymentNl from "@/lang/translations/nl/payment";

import reservationEn from "@/lang/translations/en/reservation";
import reservationFr from "@/lang/translations/fr/reservation";
import reservationNl from "@/lang/translations/nl/reservation";

import validatorEn from "@/lang/translations/en/validator";
import validatorFr from "@/lang/translations/fr/validator";
import validatorNl from "@/lang/translations/nl/validator";

interface LanguageMap {
  [key: string]: string;
}

class Translator {
  private readonly files = {
    [Language.EN]: {
      general: generalEn,
      admin: adminEn,
      domotics: domoticsEn,
      laundry: laundryEn,
      payment: paymentEn,
      login: loginEn,
      reservation: reservationEn,
      validator: validatorEn
    },
    [Language.FR]: {
      general: generalFr,
      admin: adminFr,
      domotics: domoticsFr,
      laundry: laundryFr,
      payment: paymentFr,
      login: loginFr,
      reservation: reservationFr,
      validator: validatorFr
    },
    [Language.NL]: {
      general: generalNl,
      admin: adminNl,
      domotics: domoticsNl,
      laundry: laundryNl,
      payment: paymentNl,
      login: loginNl,
      reservation: reservationNl,
      validator: validatorNl
    }
  } as NestedRecord;

  setLanguage(lang: string) {
    localStorage.setItem("language", lang);
  }
  getLanguage(): string {
    let lang = localStorage.getItem("language");
    if (!lang) {
      this.setLanguage("en");
      lang = "en";
    }
    return lang;
  }

  trans(code: string, vars: Record<string, string> = {}): string {
    const lang = this.getLanguage();
    let file = this.files[lang] as NestedRecord;

    const keys = code.split(".");

    const lastIndex = keys.length - 1;
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];

      if (!(key in file)) return code;

      if (i === lastIndex && typeof file[key] === "string") {
        let result = file[key] as string;

        for (const placeholder in vars) {
          const replacement = vars[placeholder];
          result = result.replaceAll(`[${placeholder}]`, replacement);
        }
        return result;
      }

      file = file[key] as NestedRecord;
    }
    return code;
  }
  formatCurrency(value: number): string {
    if (value === undefined) return "";
    return value.toLocaleString(this.getLocale(), {
      style: "currency",
      currency: "EUR"
    });
  }
  formatNumber(value: number): string {
    if (value === undefined) return "";
    return value.toLocaleString(this.getLocale(), {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  }
  getLocale(): string {
    const languageCode = localStorage.getItem("language") ?? "en";
    const languageMap: LanguageMap = {
      EN: "en-GB",
      NL: "nl-BE",
      FR: "fr-BE"
    };

    return languageMap[languageCode.toUpperCase()] || "en-GB";
  }
}

export default new Translator();
