export interface IGuestData {
  id: number;
  gender?: string;
  first_name?: string;
  last_name?: string;
  street?: string;
  zipcode?: string;
  city?: string;
  country?: string;
  email?: string;
  phone_number?: string;
  language?: string;
}

export default class Guest {
  public id: number;
  public gender: string;
  public firstName: string;
  public lastName: string;
  public street: string;
  public zipcode: string;
  public city: string;
  public country: string;
  public email: string;
  public phoneNumber: string;
  public language: string;

  constructor(data: IGuestData) {
    this.id = data.id;
    this.gender = data.gender ?? "";
    this.firstName = data.first_name ?? "";
    this.lastName = data.last_name ?? "";
    this.street = data.street ?? "";
    this.zipcode = data.zipcode ?? "";
    this.city = data.city ?? "";
    this.country = data.country ?? "";
    this.email = data.email ?? "";
    this.phoneNumber = data.phone_number ?? "";
    this.language = data.language ?? "";
  }

  toJson(): Record<string, unknown> {
    return {
      id: this.id,
      gender: this.gender,
      first_name: this.firstName,
      last_name: this.lastName,
      street: this.street,
      zipcode: this.zipcode,
      city: this.city,
      country: this.country,
      email: this.email,
      phone_number: this.phoneNumber,
      language: this.language
    };
  }

}
