export interface IExpressCheckinInfoData {
  code_door: null|string;
  code_vault: null|string;
  letter: null|string;
}

export default class ExpressCheckinInfo {
  public codeDoor: null|string;
  public codeVault: null|string;
  public letter: null|string;

  constructor(data: IExpressCheckinInfoData) {
    this.codeDoor = data.code_door ?? null;
    this.codeVault = data.code_vault ?? null;
    this.letter = data.letter ?? null;
  }

  toJson(): Record<string, unknown> {
    return {
      code_door: this.codeDoor,
      code_vault: this.codeVault,
      letter: this.letter
    };
  }
}
