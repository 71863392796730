export interface IPaymentData {
  datetime: string;
  description: string;
  amount: number;
  method: null|string;
  method_type: null|string;
  cardholder_name: null|string;
}

export default class Payment {
  public datetime: string;
  public description: string;
  public amount: number;
  public method: null|string;
  public methodType: null|string;
  public cardholderName: null|string;

  constructor(data: IPaymentData) {
    this.datetime = data.datetime;
    this.description = data.description;
    this.amount = data.amount;
    this.method = data.method;
    this.methodType = data.method_type;
    this.cardholderName = data.cardholder_name;
  }

  toJson(): Record<string, unknown> {
    return {
      datetime: this.datetime,
      description: this.description,
      amount: this.amount,
      method: this.method,
      method_type: this.methodType,
      cardholder_name: this.cardholderName
    };
  }

}
