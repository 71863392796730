export default {
  contract_id: "Contract ID",
  checkin: {
    fill_in: "Vul de gastgegevens in",
    private: "Privé",
    business: "Bedrijf",
    company_name: "Bedrijfsnaam",
    gender: "Geslacht",
    first_name: "Voornaam",
    last_name: "Achternaam",
    street: "Straat",
    zip: "Postcode",
    phone_number: "Telefoonnummer",
    email: "E-mail",
    continue: "Ga verder"
  },
  options: {
    options: "Opties",
    select_additional: "Selecteer extra opties"
  },
  overview: {
    overview: "Overzicht",
    name: "Naam",
    address: "Adres",
    phone: "Telefoon",
    email: "E-mail",
    invoice_lines: "Factuurregels",
    removed_options: "Verwijderde opties",
    added_options: "Nieuw toegevoegde opties",
    continue_pay_later: "Ga verder & betaal later",
    contract: "Contract",
    guest_info: "Gastinformatie",
    total_pay_today: "Totaal te betalen vandaag: ",
    confirm_and_pay: "Bevestigen & Nu betalen",
    terms_and_conditions: "Algemene voorwaarden",
    accept_terms_and_conditions: "Ik accepteer de algemene voorwaarden"

  },
  passport: {
    passport_scanner: "Paspoortscanner",
    legal_1: "Vanwege wettelijke vereisten moeten we uw paspoort scannen ter verificatie. Upload een duidelijke foto van de zijde met de Machine Readable Zone (MRZ).",
    legal_2: "Het bestaat uit twee of drie regels cijfers, letters en symbolen. Deze informatie is cruciaal voor de authenticatie van uw paspoort.",
    mrz_look_like: "Hoe ziet een MRZ eruit?",
    mrz_example: "MRZ-voorbeeld",
    close: "Sluiten",
    upload_passport: "Upload een pasfoto",
    warning_overwrite: "Waarschuwing: het uploaden van een nieuw paspoort zal uw huidige paspoort overschrijven.",
    rejected: "Uw paspoort is afgewezen.",
    reason: "Reden: ",
    overwrite_passport: "Paspoort overschrijven",
    process_passport: "Paspoort verwerken",
    passport_data: "Paspoortgegevens",
    gender: "Geslacht",
    nationality: "Nationaliteit",
    first_name: "Voornaam",
    last_name: "Achternaam",
    is_data_correct: "Zijn de paspoortgegevens correct?",
    yes: "Ja",
    no: "nee",
    password_approved: "Paspoort goedgekeurd",
    awaiting_approval: "In afwachting van handmatige paspoortcontrole door het verkoopteam",
    failed_to_process: "Kan het paspoort niet verwerken. Zorg ervoor dat het paspoort duidelijk zichtbaar is.",
    passport_confirmation: "Paspoortbevestiging",
    picture_correct: "Foto is correct",
    correct_picture_wrong_data: "De afbeelding die je hebt geüpload is correct, maar de gegevens worden verkeerd gelezen: ",
    sales_team_manual_lookup: "ons verkoopteam zal deze foto handmatig inspecteren en de juiste gegevens invullen.",
    upload_new_picture: "Nieuwe foto uploaden",
    remove_picture_upload_new: "De huidige foto wordt verwijderd en u kunt een nieuwe pasfoto uploaden."
  },
  key_pickup: {
    key_pickup_instructions: "Instructies voor het ophalen van de sleutel",
    gate_code: "Poortcode: ",
    vault_code: "Kluiscode: ",
    check_in: "U kunt inchecken bij onze bemande receptie op [arrival_date] tussen [checkin_time] en [checkout_time]",
    late_arrival_notice: "Let op: Aankomst na [checkin_day] of een vroege check-in geboekt?",
    follow_instructions: "Volg dan de volgende instructies: ",
    access_entrance: "Om de toegangshal te betreden, voert u code ",
    entrance_description: "In de toegangshal ziet u de kluis waar u de sleutel van uw kamer en wat informatie zult vinden.",
    code_safe: "De code voor deze kluis is ",
    safe_instructions: "Draai het slot van de kluis naar rechts om de deur te openen/links om weer te sluiten. ",
    inside_envelope_letter: "Binnenin vindt u een enveloppe met letter ",
    personal_envelope: ", dit is uw persoonlijke enveloppe. ",
    inside_envelope: "Binnenin vindt u een enveloppe met ",
    your_name: "UW NAAM ",
    key_document_inside: "Uw sleutel met kamernummer en document bevinden zich binnenin deze enveloppe, samen met de contactgegevens. ",
    contact_residence: "In geval van problemen kunt u contact opnemen met de residentiebeheerder op het volgende noodnummer: ",
    complete_checkin_for_instructions: "Voltooi uw check-in om toegang te krijgen tot uw sleutelafhaalinstructies.",
    instructions_on_arrival_day: "Op de dag van aankomst vindt u hier uw incheckinstructies."
  }


}
