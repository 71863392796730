import { createApp } from "vue";
import VueHotJar from "vue-hotjar-next";
import vuetify from "@/plugins/vuetify";
import pinia from "@/plugins/pinia";
import "./registerServiceWorker";
import router from "@/router";
import routerPlugin from "@/plugins/routerPlugin";
import translatorPlugin from "@/plugins/translatorPlugin";
import App from "./App.vue";
import validatorPlugin from "@/plugins/validatorPlugin";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(fas);

const HOTJAR_ID = Number(process.env.VUE_APP_HOTJAR_ID);

createApp(App)
  .use(vuetify)
  .use(router)
  .use(routerPlugin)
  .use(translatorPlugin)
  .use(validatorPlugin)
  .use(VueHotJar,
    {
      id: HOTJAR_ID,
      isProduction: process.env.VUE_APP_NODE_ENV === "production"
    })
  .use(pinia)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");
