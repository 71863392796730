import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import useStore from "@/stores";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/HomeView.vue")
  },
  {
    path: "/reservation-home",
    name: "reservation-home",
    component: () => import("@/views/ReservationHomeView.vue")
  },
  {
    path: "/reservation-home/extend-reservation",
    name: "extend-reservation",
    component: () => import("@/components/extend/ExtendReservationDialog.vue"),
    props: true
  },
  {
    path: "/reservation-home/pay-overview",
    name: "pay-overview",
    component: () => import("@/components/PayReservationOverview.vue"),
    props: true
  },
  {
    path: "/reservation-home/payment-done",
    name: "reservation-home-confirmation",
    component: () => import("@/views/manage-reservation/ReservationPaymentDone.vue"),
    props: true
  },
  {
    path: "/auth",
    name: "auth",
    component: () => import("@/views/LoginTypeView.vue"),
    props: true,
    meta: {
      title: "Login"
    }
  },
  {
    path: "/domotics",
    name: "domotics",
    component: () => import("@/views/domotics/DomoticsView.vue"),
    props: true,
    meta: {
      title: "Domotics"
    }
  },
  {
    path: "/unit-picker",
    name: "unit-picker",
    component: () => import("@/views/UnitPicker.vue"),
    props: true,
    meta: {
      title: "UnitPicker"
    }
  },
  {
    path: "/domotics/thermostat",
    name: "thermostat",
    component: () => import("@/views/domotics/ThermostatView.vue"),
    props: true,
    meta: {
      title: "Thermostat"
    }
  },
  {
    path: "/domotics/statistics",
    name: "statistics",
    component: () => import("@/views/domotics/StatisticsView.vue"),
    props: true,
    meta: {
      title: "Statistics"
    }
  },
  {
    path: "/domotics/devices",
    name: "devices",
    component: () => import("@/views/domotics/DevicesView.vue"),
    props: true,
    meta: {
      title: "Devices"
    }
  },
  {
    path: "/laundry",
    name: "laundry",
    component: () => import("@/views/laundry/LaundryView.vue"),
    props: true
  },
  {
    path: "/payment",
    name: "payment",
    component: () => import("@/views/payment/PaymentMethodView.vue"),
    props: true
  },
  {
    path: "/laundry/done",
    name: "payment-done",
    component: () => import("@/views/payment/PaymentDone.vue"),
    props: true
  },
  {
    path: "/reservation",
    name: "reservation",
    component: () => import("@/views/manage-reservation/ReservationComponent.vue"),
    props: true
  },
  {
    path: "/reservation/checkin",
    name: "checkin",
    component: () => import("@/views/manage-reservation/CheckinView.vue"),
    props: true
  },
  {
    path: "/reservation/options",
    name: "reservation-options",
    component: () => import("@/views/manage-reservation/ReservationOptions.vue"),
    props: true
  },
  {
    path: "/reservation/overview",
    name: "reservation-overview",
    component: () => import("@/views/manage-reservation/ReservationOverview.vue"),
    props: true
  },
  {
    path: "/reservation/passport-scanner",
    name: "passport-scanner",
    component: () => import("@/views/manage-reservation/PassportScanner.vue"),
    props: true
  },
  {
    path: "/reservation/payment-done",
    name: "reservation-confirmation",
    component: () => import("@/views/manage-reservation/ReservationPaymentDone.vue"),
    props: true
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  const store = useStore();
  const contract = localStorage.getItem("contract");
  if (to.name === "auth"
    || to.name === "login-guest"
    || to.name === "login-reservation"
    || to.name === "unit-picker"
    || to.name === "reservation-home"
  ) {
    next();
  } else if (!contract && !store.session.contractId) {
    store.app.authRedirectsTo = to.name?.toString() ?? "";

    next({ name: "auth" });
  } else {
    next();
  }
});

export default router;
