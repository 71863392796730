import ReceptionDayData, {IReceptionDayData} from "@/types/clients/esquire/data/general/reception-day-data";

export interface IReceptionCheckInOutData {
  fri: IReceptionDayData;
  mon: IReceptionDayData;
  sat: IReceptionDayData;
  sun: IReceptionDayData;
  thu: IReceptionDayData;
  tue: IReceptionDayData;
  wed: IReceptionDayData;
}

export default class ReceptionCheckInOutData {
  public fri: IReceptionDayData;
  public mon: IReceptionDayData;
  public sat: IReceptionDayData;
  public sun: IReceptionDayData;
  public thu: IReceptionDayData;
  public tue: IReceptionDayData;
  public wed: IReceptionDayData;

  constructor(data: IReceptionCheckInOutData) {
    this.fri = new ReceptionDayData(data.fri);
    this.mon = new ReceptionDayData(data.mon);
    this.sat = new ReceptionDayData(data.sat);
    this.sun = new ReceptionDayData(data.sun);
    this.thu = new ReceptionDayData(data.thu);
    this.tue = new ReceptionDayData(data.tue);
    this.wed = new ReceptionDayData(data.wed);

  }
}
