export default {
  home: {
    laundry: "De Was",
    description: "Selecteer je apparaat, betaal en gebruik het direct!",
    scan_QR: "Scan QR-code"
  },
  washing_machine: "Wasmachine",
  tumble_dryer: "Droogkast",
  dialog: {
    attention: " Let op ",
    text_1: "Wilt u ",
    text_2: " betalen om 90 minuten gebruik te maken van onze ",
    text_3: "?",
    text_4: "Na betaling zal de machine onmiddellijk starten.",
    text_5: "We adviseren om eerst uw wasgoed in de machine te stoppen.",
    text_6: "U krijgt 90 minuten stroom.",
    text_programs: "U krijgt 90 minuten stroom, dit is voldoende voor de volgende programma's:",
    programs: {
      washer_1: "Xpress supershort (14 min)",
      washer_2: "Daily express (28 min)",
      washer_3: "Donker op 40°C op 800 toeren (90 min)",
      washer_4: "Synthetisch op 40°C op 800 toeren (90 min)"
    }
  },
  dialog_ba: {
    attention: " Let op ",
    text_1: "Wilt u ",
    text_2: " betalen om 120 minuten gebruik te maken van onze ",
    text_3: "?",
    text_4: "Na betaling zal de machine onmiddellijk starten.",
    text_5: "We adviseren om eerst uw wasgoed in de machine te stoppen.",
    text_6: "U krijgt 120 minuten stroom.",
    text_programs: "U krijgt 120 minuten stroom, dit is voldoende voor de volgende programma's:",
    programs: {
      washer_1: "Xpress supershort (14 min)",
      washer_2: "Daily express (28 min)",
      washer_3: "Donker op 40°C op 800 toeren (90 min)",
      washer_4: "Synthetisch op 40°C op 800 toeren (90 min)"
    }
  },
  available_in: "Beschikbaar over",
  no_laundry_devices_found: "Er konden geen wasapparaten gevonden worden voor dit gebouw"
};
