import dayjs from "dayjs";

export interface IInvoiceLineData {
  type_id: number;
  type_name: string;
  description: string;
  start_date: string;
  end_date: string;
  payable_at: string;
  guest_count: number;
  price: number;
  vat_price: number;
  vat_percentage: number;
  option_type_id: number|null;
}

export default class InvoiceLine {
  public typeId: number;
  public typeName: string;
  public description: string;
  public startDate: string;
  public endDate: string;
  public payableAt: string;
  public guestCount: number;
  public price: number;
  public vatPrice: number;
  public vatPercentage: number;
  public optionTypeId: number|null;

  constructor(data: IInvoiceLineData) {
    this.typeId = data.type_id;
    this.typeName = data.type_name;
    this.description = data.description;
    this.startDate = data.start_date;
    this.endDate = data.end_date;
    this.payableAt = data.payable_at;
    this.guestCount = data.guest_count;
    this.price = data.price;
    this.vatPrice = data.vat_price;
    this.vatPercentage = data.vat_percentage;
    this.optionTypeId = data.option_type_id;
  }

  toJson(): Record<string, unknown> {
    return {
      type_id: this.typeId,
      type_name: this.typeName,
      description: this.description,
      start_date: this.startDate,
      end_date: this.endDate,
      payable_at: this.payableAt,
      guest_count: this.guestCount,
      price: this.price,
      vat_price: this.vatPrice,
      vat_percentage: this.vatPercentage,
      option_type_id: this.optionTypeId
    };
  }


  calculateDays(): number {
    return dayjs(this.endDate).diff(this.startDate, "days");
  }
}
