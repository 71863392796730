export default {
  home: {
    reservation_filters: "Filtres de réservation",
    ongoing_reservations: "Réservations en cours",
    future_reservations: "Réservations futures",
    open_balance: "Solde ouvert",
    past_reservations: "Réservations passées",
    reference_number: "Numéro de référence",
    check_in: "Arrivée",
    check_out: "Départ",
    nights: "Nuits",
    to_pay_today: "À payer aujourd'hui",
    cancelled: "ANNULÉ",
    extend_my_reservation: "PROLONGER MA RÉSERVATION",
    rooms: "Chambres",
    contract_id: "Identifiant du contrat",
    arrival: "Arrivée",
    departure: "Départ",
    guests: "Invités",
    unit_type: "Type d'unité",
    main_guest: "Client principal",
    fetching_reservations: "Récupération des réservations"
  },
  extend: {
    find_availabilities: "Trouver des disponibilités",
    select_contracts_extend: "Sélectionner des contrats à prolonger",
    select_all: "Tout sélectionner",
    deselect_all: "Tout désélectionner",
    id: "ID",
    stay: "Séjour",
    unit: "Chambre",
    guest: "Invité",
    pick_new_departure: "Choisir une nouvelle date de départ",
    extend_for_price: "Prolongez votre séjour dans cette chambre pour ",
    extend_for: "Prolonger pour ",
    days: "jours",
    pick_options: "Choisir vos options",
    previous_contract: "Contrat précédent",
    next_contract: "Contrat suivant",
    reservation_overview: "Aperçu de la réservation",
    total_balance: "Solde total",
    confirm_extension: "Je confirme mon extension en cliquant sur procéder au paiement",
    proceed_to_payment: "Procéder au paiement",
    go_to_overview: "Aller à l'aperçu",
    previous_balance: "Solde précédent",
    balance_after_extension: "Solde après extension"
  },
  error: {
    no_availabilities: "Aucune disponibilité trouvée pour cette date. Veuillez sélectionner une autre date ou contacter notre équipe commerciale.",
    extend_success: "Réservation prolongée avec succès.",
    extend_failed: "Échec de la prolongation de la réservation. Veuillez contacter notre équipe commerciale."
  }
}
