
export type OptionData = {
  id: number;
  type_id: number;
  collection_id: number;
  building_id: string;
  unit_type_id: string;
  language: string;
  name: string;
  description: string;
  price: number;
  price_per_unit_of: string;
  price_per_interval_of: string;
  permission: string;
  vat_percentage: number;
};

export default class Option {
  public id: number;
  public typeId: number;
  public collectionId: number;
  public buildingId: string;
  public unitTypeId: string;
  public language: string;
  public name: string;
  public description: string;
  public price: number;
  public pricePerUnitOf: string;
  public pricePerIntervalOf: string;
  public permission: string;
  public vatPercentage: number;

  constructor(data: OptionData) {
    this.id = data.id as number;
    this.typeId = data.type_id as number;
    this.collectionId = data.collection_id as number;
    this.buildingId = data.building_id as string;
    this.unitTypeId = data.unit_type_id as string;
    this.language = data.language as string;
    this.name = data.name as string;
    this.description = data.description as string;
    this.price = data.price as number;
    this.pricePerUnitOf = data.price_per_unit_of as string;
    this.pricePerIntervalOf = data.price_per_interval_of as string;
    this.permission = data.permission as string;
    this.vatPercentage = data.vat_percentage as number;
  }

  toJson(): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (this.id !== null) json.id = this.id;
    if (this.typeId !== null) json.type_id = this.typeId;
    if (this.collectionId !== null) json.collection_id = this.collectionId;
    if (this.buildingId !== null) json.building_id = this.buildingId;
    if (this.unitTypeId !== null) json.unit_type_id = this.unitTypeId;
    if (this.language !== null) json.language = this.language;
    if (this.name !== null) json.name = this.name;
    if (this.description !== null) json.description = this.description;
    if (this.price !== null) json.price = this.price;
    if (this.pricePerUnitOf !== null) json.price_per_unit_of = this.pricePerUnitOf;
    if (this.pricePerIntervalOf !== null) json.price_per_interval_of = this.pricePerIntervalOf;
    if (this.permission !== null) json.permission = this.permission;
    if (this.vatPercentage !== null) json.vat_percentage = this.vatPercentage;

    return json;
  }
}
